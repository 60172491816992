import React from 'react';

export default function AboutPage() {

    return (
        <div>
            <h2 className="text-center text-dark"> Hello, there!</h2>
            <p className="text-center text-dark"> This page has not been fully implemented.
                Please come back and look again later! </p>
        </div>);
}